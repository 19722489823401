@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 100%; }

#root {
  height: 100%; }

.content-body {
  height: 100%; }

.bold {
  font-weight: bold; }

a {
  text-decoration: none !important; }

.content-page-title {
  font-size: 1.5em;
  font-weight: 500; }

.content-page-subtitle {
  font-size: 1.15em;
  font-weight: 400;
  margin-bottom: 25px; }

.ant-switch-checked {
  background: #73d13d !important; }

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 4 * ((100vw - 320px) / 961)); } }

@media screen and (min-width: 1281px) {
  html {
    font-size: 16px; } }

.ant-modal-body {
  max-height: 700px !important;
  overflow: scroll !important; }

.clickable {
  background-color: #0068ff !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #0068ff !important;
  border-radius: 2px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 0 16px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-shadow: none !important;
  cursor: pointer; }
  .clickable .ant-select-selection {
    background-color: #0068ff !important; }
  .clickable .ant-select-selection__placeholder {
    color: #fff; }

.clickable:hover {
  background-color: #0063f2 !important;
  border: 2px solid #0058d6 !important;
  color: #fff !important; }

.clickable:active {
  background-color: #0058d6 !important;
  color: #fff !important;
  box-shadow: none !important; }

.ant-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative; }
  .ant-pagination .ant-pagination-total-text {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    position: absolute;
    font-size: 12px;
    right: 120px;
    color: #898989; }
  .ant-pagination .ant-pagination-item {
    border-radius: 2px;
    font-size: 12px;
    border: 1px solid #ffffff;
    box-shadow: 0 3px 8px #00000016; }
    .ant-pagination .ant-pagination-item a {
      color: #212121;
      font-size: 12px; }
  .ant-pagination .ant-pagination-jump-next-custom-icon {
    margin-right: 0px; }
  .ant-pagination .ant-pagination-item-before-jump-next {
    margin-right: 0px; }
  .ant-pagination .ant-pagination-prev {
    border-radius: 2px;
    border: 2px solid #ffffff; }
    .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
      border-radius: 0px;
      border: 0px; }
  .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    border-radius: 2px;
    border: 0px; }
  .ant-pagination .ant-pagination-item:hover {
    background-color: #f5faff;
    border: 1px solid #1268fb; }
    .ant-pagination .ant-pagination-item:hover a {
      color: #212121;
      border: 0px solid #1268fb;
      border-radius: 0px; }
  .ant-pagination .ant-pagination-item-active {
    background-color: #1268fb;
    border-radius: 2px;
    border: 1px solid #1268fb; }
    .ant-pagination .ant-pagination-item-active a {
      color: #fff;
      border-radius: 0px; }
  .ant-pagination .ant-pagination-item-active:hover {
    background-color: #1268fb;
    border: 1px solid #1268fb;
    border-radius: 2px; }
    .ant-pagination .ant-pagination-item-active:hover a {
      color: #fff; }
  .ant-pagination .ant-pagination-item-active:focus {
    background-color: #1268fb;
    border: 1px solid #1268fb;
    border-radius: 2px; }
    .ant-pagination .ant-pagination-item-active:focus a {
      color: #fff; }
  .ant-pagination .ant-pagination-options {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 48px;
    justify-content: flex-end; }
    .ant-pagination .ant-pagination-options .ant-pagination-options-size-changer {
      order: 2;
      margin-right: 0px;
      min-width: 103px; }
      .ant-pagination .ant-pagination-options .ant-pagination-options-size-changer .ant-select-selection {
        border-radius: 2px;
        border: 0px solid #ffffff; }
        .ant-pagination .ant-pagination-options .ant-pagination-options-size-changer .ant-select-selection .ant-select-selection-selected-value {
          color: #212121;
          font-size: 12px;
          width: 80px; }
    .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
      position: absolute;
      left: 0px;
      font-size: 12px;
      color: #898989; }
      .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
        border-radius: 2px;
        border: 1px solid #ffffff;
        color: #212121;
        height: 32px;
        width: 36px;
        padding: 0;
        text-align: center; }

.clickable-dropdown {
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  background-color: #2a9c27 !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #2a9c27 !important;
  border-radius: 2px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  text-shadow: none !important; }
  .clickable-dropdown span {
    color: #fff; }

.clickable-dropdown:hover {
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  background-color: #2bc822 !important;
  border: 2px solid #229d1b !important;
  color: #fff !important;
  box-shadow: none !important; }

.clickable-dropdown:active {
  background-color: #229d1b !important;
  color: #fff !important; }

.green-clickable {
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  background-color: #2bc822 !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #2bc822 !important;
  border-radius: 2px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  text-shadow: none !important; }
  .green-clickable span {
    color: #fff; }

.green-clickable:hover {
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  background-color: #2bc822 !important;
  border: 2px solid #229d1b !important;
  color: #fff !important;
  box-shadow: none !important; }

.green-clickable:active {
  background-color: #229d1b !important;
  color: #fff !important; }

.green-clickable.ant-btn-disabled {
  background-color: #2bc822 !important;
  border-color: #2bc822 !important;
  opacity: 0.5;
  text-shadow: none !important; }
  .green-clickable.ant-btn-disabled span {
    color: #fff; }

.border-orange {
  position: relative;
  z-index: 9;
  border: 1px solid red;
  border-radius: 5px;
  -webkit-animation: pulse-black 2s infinite;
          animation: pulse-black 2s infinite; }
  .border-orange .ant-input:hover {
    border: none; }
  .border-orange .ant-calendar-picker:hover {
    border: none; }
  .border-orange .ant-calendar-picker:hover .ant-calendar-picker-input {
    border: none; }

.ant-calendar-picker-input.ant-input {
  border-radius: 0px !important; }

.ant-input-search.ant-input-search-enter-button.ant-input-affix-wrapper {
  border-radius: 0px !important; }
  .ant-input-search.ant-input-search-enter-button.ant-input-affix-wrapper .ant-input {
    border-radius: 0px; }

.ant-btn.ant-input-search-button.ant-btn-primary {
  border-radius: 0px !important; }

.ant-btn {
  border-radius: 2px !important; }

@-webkit-keyframes pulse-black {
  0% {
    box-shadow: 0 0 0 0 red; }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

@keyframes pulse-black {
  0% {
    box-shadow: 0 0 0 0 red; }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

.login-page-container {
  width: 100%;
  height: 100%;
  background-image: url(/static/media/login_background.6f5e1683.svg);
  background-color: #f7f7fc;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed; }

.login-container {
  width: 536px;
  max-width: 100%;
  height: 100%;
  margin: auto;
  padding: 100px 15px 0 15px; }
  .login-container .logo {
    margin-bottom: 18px;
    width: 200px; }
  .login-container .tagline {
    color: rgba(0, 0, 0, 0.45);
    margin: 0 0 42px 0; }
  .login-container .ant-tabs-bar {
    border: 0;
    text-align: center; }
  .login-container .login-form-forgot,
  .login-container .login-form-signup-here {
    float: right; }
  .login-container .login-form-button {
    width: 100%;
    margin: 25px 0; }
  .login-container .register-tag-line {
    margin: 15px 0 10px 0; }
  .login-container .login-page-footer {
    position: -webkit-sticky;
    position: sticky;
    top: calc(100% - 55px); }
    .login-container .login-page-footer .footer-links {
      list-style-type: none;
      padding: 0; }
      .login-container .login-page-footer .footer-links li {
        display: inline-block;
        margin: 0 20px; }
        .login-container .login-page-footer .footer-links li a {
          color: rgba(0, 0, 0, 0.45); }
        .login-container .login-page-footer .footer-links li a:hover {
          color: black; }

.header.ant-layout-header {
  padding: 0 20px;
  background: #001e41;
  position: relative;
  z-index: 9; }
  .header.ant-layout-header .navbar-ham {
    display: none;
    color: white;
    font-size: 24px; }
  .header.ant-layout-header .ant-menu-dark {
    background: transparent;
    float: right; }
  .header.ant-layout-header .ant-menu-item {
    padding: 0 10px; }
  .header.ant-layout-header .ant-menu-item-selected,
  .header.ant-layout-header .ant-menu-item-active {
    background: #00162f; }
  .header.ant-layout-header .ant-menu-item .anticon {
    font-size: 14px; }
  .header.ant-layout-header .ant-avatar {
    background-color: #fc4747;
    border: 2px solid white;
    box-sizing: content-box; }
  .header.ant-layout-header .avatar-details {
    display: inline-block;
    margin: 0 10px;
    font-size: 12px;
    line-height: 20px;
    vertical-align: middle; }

.ant-dropdown-menu-item a {
  padding: 10px 25px; }

@media (max-width: 768px) {
  .header .ant-menu {
    display: none; }
  .header.ant-layout-header .navbar-ham {
    display: block;
    float: right; } }

@media only screen and (max-width: 1279px) {
  .header.ant-layout-header .logo {
    float: left;
    width: 160px; }
    .header.ant-layout-header .logo a img {
      width: 100%;
      padding: 18px 23px; } }

@media only screen and (min-width: 1280px) {
  .header.ant-layout-header .logo {
    float: left;
    width: 240px; }
    .header.ant-layout-header .logo a img {
      width: 200px;
      padding: 18px 23px; } }

.sidebar {
  height: calc(100vh - 64px);
  overflow: auto; }
  .sidebar .ant-menu {
    background: #e6ebf1;
    font-size: 12px; }
  .sidebar .submenu-item {
    padding-left: 48px !important; }
  .sidebar .ant-menu-item {
    font-size: 12px; }
    .sidebar .ant-menu-item .ant-menu-submenu-title {
      color: rgba(29, 29, 29, 0.65);
      font-size: 12px; }
      .sidebar .ant-menu-item .ant-menu-submenu-title a:hover {
        color: #212121; }
  .sidebar .ant-menu-item:hover {
    background-color: transparent !important;
    color: black; }
    .sidebar .ant-menu-item:hover a {
      color: black; }
  .sidebar .ant-menu-item-selected {
    background-color: transparent !important; }
    .sidebar .ant-menu-item-selected a {
      color: black;
      font-weight: 500; }
  .sidebar .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: transparent !important;
    color: black;
    font-weight: 500; }
  .sidebar .ant-menu-item-selected:after {
    display: none; }
  .sidebar .ant-layout-sider-zero-width-trigger {
    display: none; }
  .sidebar .show-on-small-screen {
    display: none; }
  .sidebar .ant-menu-vertical .ant-menu-item,
  .sidebar .ant-menu-vertical-left .ant-menu-item,
  .sidebar .ant-menu-vertical-right .ant-menu-item,
  .sidebar .ant-menu-inline .ant-menu-item,
  .sidebar .ant-menu-vertical .ant-menu-submenu-title,
  .sidebar .ant-menu-vertical-left .ant-menu-submenu-title,
  .sidebar .ant-menu-vertical-right .ant-menu-submenu-title,
  .sidebar .ant-menu-inline .ant-menu-submenu-title {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0 16px;
    overflow: hidden;
    font-size: 12px;
    line-height: 40px;
    text-overflow: ellipsis;
    font-weight: 400;
    letter-spacing: 0.032em; }

@media (max-width: 768px) {
  .sidebar .show-on-small-screen {
    display: block; }
  .sidebar-open {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important; } }

@media screen and (min-width: 1360px) {
  .sidebar-open {
    flex: 0 0 13.5rem !important;
    max-width: 13.5rem !important;
    min-width: 13.5rem !important;
    width: 13.5rem !important; } }

.no-table-data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.11rem 0; }
  .no-table-data svg {
    width: 21.51rem;
    height: 16.44rem;
    max-width: 100%;
    margin-right: 1.36rem; }
  .no-table-data .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 24.21rem; }
    .no-table-data .text .heading {
      font-size: 1.43rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0.36rem; }
    .no-table-data .text .content {
      text-align: left;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0;
      font-size: 1.143rem;
      font-weight: 400; }

.dashboard-header {
  background: #f7f7fc;
  color: #000; }

.dashboard-content {
  background: #f0f2f5;
  padding: 56px;
  margin: 0;
  height: 100%;
  overflow: scroll; }

.dashboard-content.no-sidebar {
  width: 100%;
  max-width: 100%;
  padding: 0; }

@media (max-width: 768px) {
  .dashboard-content {
    padding: 15px; } }

