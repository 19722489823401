@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 100%;
}

#root {
  height: 100%;
}

.content-body {
  height: 100%;
}

.bold {
  font-weight: bold;
}

a {
  text-decoration: none !important;
}

.content-page-title {
  font-size: 1.5em;
  font-weight: 500;
}

.content-page-subtitle {
  font-size: 1.15em;
  font-weight: 400;
  margin-bottom: 25px;
}

.ant-switch-checked {
  background: rgb(115, 209, 61) !important;
}

@media screen and (min-width: 320px) {
  html {
    font-size: calc(12px + 4 * ((100vw - 320px) / 961));
  }
}

@media screen and (min-width: 1281px) {
  html {
    font-size: 16px;
  }
}

.ant-modal-body {
  max-height: 700px !important;
  overflow: scroll !important;
}

// clickable blue button

.clickable {
  background-color: #0068ff !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #0068ff !important;
  border-radius: 2px !important;
  width: fit-content !important;
  padding: 0 16px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-shadow: none !important;
  cursor: pointer;

  .ant-select-selection {
    background-color: #0068ff !important;
  }

  .ant-select-selection__placeholder {
    color: #fff;
  }
}

.clickable:hover {
  background-color: #0063f2 !important;
  border: 2px solid #0058d6 !important;
  color: #fff !important;
}

.clickable:active {
  background-color: #0058d6 !important;
  color: #fff !important;
  box-shadow: none !important;
}

// pagination style across all pages 1440px
.ant-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .ant-pagination-total-text {
    min-width: fit-content;
    position: absolute;
    font-size: 12px;
    right: 120px;
    color: #898989;
  }

  .ant-pagination-item {
    border-radius: 2px;
    font-size: 12px;
    border: 1px solid #ffffff;
    box-shadow: 0 3px 8px #00000016;

    a {
      color: #212121;
      font-size: 12px;
    }
  }

  .ant-pagination-jump-next-custom-icon {
    margin-right: 0px;
  }

  .ant-pagination-item-before-jump-next {
    margin-right: 0px;
  }

  .ant-pagination-prev {
    border-radius: 2px;
    border: 2px solid #ffffff;

    .ant-pagination-item-link {
      border-radius: 0px;
      border: 0px;
    }
  }

  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 2px;
      border: 0px;
    }
  }

  .ant-pagination-item:hover {
    background-color: #f5faff;

    border: 1px solid #1268fb;

    a {
      color: #212121;
      border: 0px solid #1268fb;

      border-radius: 0px;
    }
  }

  .ant-pagination-item-active {
    background-color: #1268fb;
    border-radius: 2px;
    border: 1px solid #1268fb;
    a {
      color: #fff;
      border-radius: 0px;
    }
  }
  .ant-pagination-item-active:hover {
    background-color: #1268fb;
    border: 1px solid #1268fb;
    border-radius: 2px;
    a {
      color: #fff;
    }
  }

  .ant-pagination-item-active:focus {
    background-color: #1268fb;
    border: 1px solid #1268fb;
    border-radius: 2px;
    a {
      color: #fff;
    }
  }

  .ant-pagination-options {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 48px;
    justify-content: flex-end;
    .ant-pagination-options-size-changer {
      order: 2;
      margin-right: 0px;
      min-width: 103px;

      .ant-select-selection {
        border-radius: 2px;
        border: 0px solid #ffffff;

        .ant-select-selection-selected-value {
          color: #212121;
          font-size: 12px;
          width: 80px;
        }
      }
    }

    .ant-pagination-options-quick-jumper {
      position: absolute;
      left: 0px;
      font-size: 12px;
      color: #898989;
      input {
        border-radius: 2px;
        border: 1px solid #ffffff;
        color: #212121;
        height: 32px;
        width: 36px;
        padding: 0;
        text-align: center;
      }
    }
  }
}

// @media screen and (max-width: 1438px) {
//   .ant-pagination {
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     position: relative;

//     .ant-pagination-total-text {
//       min-width: fit-content;
//       position: absolute;
//       font-size: 12px;
//       right: 120px;
//       color: #898989;
//     }

//     .ant-pagination-item {
//       border: 0 !important;
//       border-radius: 2px;
//       font-size: 12px;
//       // border: 1px solid #ffffff;
//       display: flex;
//       height: 24px;
//       width: 24px !important;
//       align-items: center;
//       min-width: 24px !important;

//       a {
//         color: #212121;
//         font-size: 12px;
//         line-height: 24px;
//         height: 24px !important;
//         width: 24px !important;
//         min-width: 24px !important;
//         padding: 0px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//     }

//     .ant-pagination-jump-next-custom-icon {
//       margin-right: 0px;
//     }

//     .ant-pagination-item-before-jump-next {
//       margin-right: 0px;
//     }

//     .ant-pagination-prev {
//       border: 0 !important;
//       border-radius: 2px;
//       font-size: 12px;
//       // border: 1px solid #ffffff;
//       display: flex;
//       height: 24px;
//       width: 24px !important;
//       align-items: center;
//       min-width: 24px !important;

//       .ant-pagination-item-link {
//         color: #212121;
//         font-size: 12px;
//         line-height: 24px;
//         height: 24px !important;
//         width: 24px !important;
//         min-width: 24px !important;
//         padding: 0px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//     }

//     .ant-pagination-next {
//       border: 0 !important;
//       border-radius: 2px;
//       font-size: 12px;
//       // border: 1px solid #ffffff;
//       display: flex;
//       height: 24px;
//       width: 24px !important;
//       align-items: center;
//       min-width: 24px !important;
//       .ant-pagination-item-link {
//         color: #212121;
//         font-size: 12px;
//         line-height: 24px;
//         height: 24px !important;
//         width: 24px !important;
//         min-width: 24px !important;
//         padding: 0px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//       }
//     }

//     .ant-pagination-item:hover {
//       background-color: #f5faff;
//       border-radius: 2px;

//       a {
//         color: #212121;
//         border: 1px solid #4398ed;
//       }
//     }

//     .ant-pagination-item-active {
//       background-color: #1268fb;
//       border-radius: 2px;
//       border: 1px solid #1268fb;
//       a {
//         color: #fff;
//         border-radius: 0px;
//       }
//     }
//     .ant-pagination-item-active:hover {
//       background-color: #1268fb;
//       border: 1px solid #1268fb;
//       border-radius: 2px;
//       a {
//         color: #fff;
//       }
//     }

//     .ant-pagination-item-active:focus {
//       background-color: #1268fb;

//       border-radius: 2px;
//       a {
//         color: #fff;
//       }
//     }

//     .ant-pagination-options {
//       width: 100%;
//       display: flex;
//       position: relative;
//       align-items: center;
//       margin-left: 48px;
//       justify-content: flex-end;
//       height: 24px;
//       .ant-pagination-options-size-changer {
//         order: 2;
//         margin-right: 0px;
//         min-width: 103px;
//         border: 0 !important;
//         border-radius: 2px;
//         font-size: 12px;
//         // border: 1px solid #ffffff;
//         display: flex;
//         height: 24px;

//         align-items: center;

//         .ant-select-selection {
//           border-radius: 2px;
//           border: 0px solid #ffffff;
//           height: 24px;
//           .ant-select-selection-selected-value {
//             color: #212121;
//             font-size: 12px;
//             height: 24px;
//             line-height: 24px;
//           }
//         }
//       }

//       .ant-pagination-options-quick-jumper {
//         position: absolute;
//         left: 0px;
//         font-size: 12px;
//         color: #898989;
//         input {
//           border-radius: 2px;
//           border: 1px solid #ffffff;
//           color: #212121;
//           height: 24px;
//           width: 32px;
//         }
//       }
//     }
//   }
// }
// clickable green button class
.clickable-dropdown {
  transition: 0.5s all;
  background-color: #2a9c27 !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #2a9c27 !important;
  border-radius: 2px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  text-shadow: none !important;

  span {
    color: #fff;
  }
}

.clickable-dropdown:hover {
  transition: 0.5s all;
  background-color: #2bc822 !important;
  border: 2px solid #229d1b !important;
  color: #fff !important;
  box-shadow: none !important;
}

.clickable-dropdown:active {
  background-color: #229d1b !important;
  color: #fff !important;
}

//  green button class
.green-clickable {
  transition: 0.5s all;

  background-color: #2bc822 !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #2bc822 !important;
  border-radius: 2px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  text-shadow: none !important;

  span {
    color: #fff;
  }
}

.green-clickable:hover {
  transition: 0.5s all;
  background-color: #2bc822 !important;
  border: 2px solid #229d1b !important;
  color: #fff !important;
  box-shadow: none !important;
}

.green-clickable:active {
  background-color: #229d1b !important;
  color: #fff !important;
}

// green disabled

.green-clickable.ant-btn-disabled {
  background-color: #2bc822 !important;
  border-color: #2bc822 !important;
  opacity: 0.5;

  text-shadow: none !important;

  span {
    color: #fff;
  }
}

// global classes
.border-orange {
  position: relative;
  z-index: 9;
  border: 1px solid red;
  border-radius: 5px;
  animation: pulse-black 2s infinite;
  .ant-input:hover {
    border: none;
  }
  .ant-calendar-picker:hover {
    border: none;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-input {
    border: none;
  }
}

// ant calander styles
.ant-calendar-picker-input.ant-input {
  border-radius: 0px !important;
}

// ant search
.ant-input-search.ant-input-search-enter-button.ant-input-affix-wrapper {
  border-radius: 0px !important;
  .ant-input {
    border-radius: 0px;
  }
}

.ant-btn.ant-input-search-button.ant-btn-primary {
  border-radius: 0px !important;
}

.ant-btn {
  border-radius: 2px !important;
}

@keyframes pulse-black {
  0% {
    box-shadow: 0 0 0 0 red;
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
